import axios from "axios";
import { api } from "../../api";

// Create Manager
const createManager = async (data) => {
  let statusData;
  await axios
    .post(api() + "/manager/addManager", data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response;
    });

  return statusData;
};

// Get all Manager
const getAllManager = async () => {
  let statusData;
  await axios
    .get(api() + "/manager/allManager/")
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Get all Manager with subscription
const getAllManagerWithSubs = async () => {
  let statusData;
  await axios
    .get(api() + "/manager/allManagerWithSubs/")
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Get Manager by id
const getOneManager = async (id) => {
  let statusData;
  await axios
    .get(api() + "/manager/oneManager/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Update Manager
const updateManager = async (data) => {
  let statusData;
  await axios
    .put(api() + "/manager/updateManager/" + data.id, data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response;
    });

  return statusData;
};

// Delete Manager
const deleteManager = async (id) => {
  let statusData;
  await axios
    .delete(api() + "/manager/deleteManager/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

export {
  createManager,
  getAllManager,
  getAllManagerWithSubs,
  getOneManager,
  updateManager,
  deleteManager,
};
