import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MaterialTable from "material-table";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from 'moment';

import { getAllManagerWithSubs } from "../../services/actions/manager/Manager";
import { updateSubscription } from "../../services/actions/subscription/Subscription";
import { getAllSubscriptionType } from "../../services/actions/subscriptionType/SubscriptionType";

function Subscription() {
  let navigate = useNavigate();
  const [managers, setManagers] = useState([]);
  const [subscriptionType, setsubscriptionType] = useState([]);
  const [open, setOpen] = useState(false);
  const [subsType, setSubsType] = useState("");
  const [subsId, setSubsId] = useState("");
  const [subsTypeId, setSubsTypeId] = useState("");
  const handleOpen = (data) => {
    setSubsType(data.subsType);
    setSubsId(data.subsId);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubsType("");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/login");
    }
    
    getAllSubscriptionType().then((res) => {
      if (res.status == 200) {
        setsubscriptionType(res.data.subscriptionType);
      }
    });

    getAllManagerWithSubs().then((res) => {
      if (res.status == 200) {
        const manager = res.data.manager;
        let data = [];
        for (let index in manager) {
          data.push({
            name: manager[index].firstName + " " + manager[index].lastName,
            mobile: manager[index].phoneNumber,
            status: manager[index].status,
            free: manager[index].freeTrial,
            subsType: manager[index].Subscription.SubscriptionType.type,
            startDate: manager[index].Subscription.startDate,
            endDate: manager[index].Subscription.endDate,
            subsId: manager[index].Subscription.id,
          });
        }
        setManagers(data);
      }
    });
  }, []);

  const handleSubsChange = (event) => {
    const type = event.target.value;
    setSubsType(type);
    if (type == "Essai gratuit") {
      setSubsTypeId(1);
    } else if (type == "Basic") {
      setSubsTypeId(2);
    } else if (type == "Standard") {
      setSubsTypeId(3);
    } else {
      setSubsTypeId(4);
    }
  };

  const handleSave = () => {
    const data = {
      id: subsId,
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).add(subsTypeId == 1 ? 2 : 6, 'months').format('YYYY-MM-DD'),
      SubscriptionTypeId: subsTypeId,
    };

    updateSubscription(data).then((res) => {
      if (res.status == 200) {
        const updateManager = [...managers];
        const findIdx = managers.findIndex(
          (item, index) => item.subsId == subsId
        );
        updateManager[findIdx].subsType = subsType;
        updateManager[findIdx].startDate = data.startDate;
        updateManager[findIdx].endDate = data.endDate;
        setManagers(updateManager);
        setOpen(false);
        setSubsType("");
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MaterialTable
        title="List of subscriptions"
        columns={[
          { title: "Name", field: "name" },
          { title: "Mobile", field: "mobile" },
          { title: "Status", field: "status" },
          {
            title: "Free Trial",
            field: "free",
          },
          {
            title: "Subscription type",
            field: "subsType",
          },
          {
            title: "Start date",
            field: "startDate",
          },
          {
            title: "End date",
            field: "endDate",
          },
        ]}
        data={managers}
        style={{ fontSize: 15, padding: "1%" }}
        actions={[
          {
            icon: "edit",
            tooltip: "Save User",
            onClick: (event, rowData) => handleOpen(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />

      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Update Subscription type</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 150 }}>
              <InputLabel htmlFor="subsType">Subscription Type</InputLabel>
              <Select
                sx={{ p: 1 }}
                value={subsType}
                onChange={handleSubsChange}
                label="subsType"
                inputProps={{
                  name: "subsType",
                  id: "subsType",
                }}
              >
                {subscriptionType.map((item, index) => (
                  <MenuItem key={index} value={item.type}>
                    {item.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Subscription;
