import axios from "axios";
import { api } from "../../api";

// Create Subscription
const createSubscription = async (data) => {
  let statusData;
  await axios
    .post(api() + "/subscription/addSubscription", data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response;
    });

  return statusData;
};

// Get all Subscription
const getAllSubscription = async () => {
  let statusData;
  await axios
    .get(api() + "/subscription/allSubscription")
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Get Subscription by id
const getOneSubscription = async (id) => {
  let statusData;
  await axios
    .get(api() + "/subscription/oneSubscription/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Update Subscription
const updateSubscription = async (data) => {
  let statusData;
  await axios
    .put(api() + "/subscription/updateSubscription/" + data.id, data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response;
    });

  return statusData;
};

// Delete Subscription
const deleteSubscription = async (id) => {
  let statusData;
  await axios
    .delete(api() + "/subscription/deleteSubscription/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

export {
  createSubscription,
  getAllSubscription,
  getOneSubscription,
  updateSubscription,
  deleteSubscription,
};
