/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

// react-router-dom components
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import {signin} from '../../../services/actions/authentication/Login';

const stylesd = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(stylesd)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="body1">
        <strong>{children}</strong>
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    paddingBottom: 40,
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function Basic() {
  
  let navigate = useNavigate();
  
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showloading, setShowloading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      navigate("/");
      window.location.reload();
    }
  }, []);

  const handleChange = (prop) => (event) => {
    const val = event.target.value;
    const userUpdate = { ...user };
    userUpdate[prop] = val;
    setUser({ ...userUpdate });
  };

  const save = (event) => {
    let formvalid = true;

    if (user.email == "" && user.password == "") {
      formvalid = false;
    }

    if (formvalid) {
      setShowloading(true);
      login();
    }
  };

  const login = () => {
    const datasend = {
      email: user.email,
      password: user.password,
    };

    signin(datasend).then((res) => {
      if (res.status == 200) {
        setShowloading(false);
        localStorage.setItem("user", JSON.stringify(res.data))
        navigate("/");
        window.location.reload()
      } else {
        setServerError(true);
        setShowloading(false);
      }
    });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
            mb={1}
          >
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onChange={handleChange("email")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onChange={handleChange("password")}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={save}
              >
                sign in
              </MDButton>
            </MDBox>
            {serverError ? (
              <MDBox mt={4} mb={1}>
                <MDTypography
                  variant="h6"
                  color="error"
                  mt={1}
                  mb={1}
                >
                  Erreur de connexion !
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
      {/*LOADING */}
      <Dialog
        key="loading"
        aria-labelledby="customized-dialog-title"
        open={showloading}
      >
        <DialogTitle id="customized-dialog-title">
          Authentification en cours...
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            <div style={{ textAlign: "center", paddingTop: 20 }}>
              <CircularProgress />
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
    </BasicLayout>
  );
}

export default Basic;
