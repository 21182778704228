import axios from "axios";
import { api } from "../../api";

// signin Manager
const signin = async (data) => {
  let statusData;
  await axios
    .post(api() + "/admin/signin", data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

export { signin };
