import axios from "axios";
import { api } from "../../api";

// Create SubscriptionType
const createSubscriptionType = async (data) => {
  let statusData;
  await axios
    .post(api() + "/subscriptionType/addSubscriptionType", data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response;
    });

  return statusData;
};

// Get all SubscriptionType
const getAllSubscriptionType = async () => {
  let statusData;
  await axios
    .get(api() + "/subscriptionType/allSubscriptionType")
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Get all SubscriptionType with subscription
const getAllSubscriptionTypeWithSubs = async (id) => {
  let statusData;
  await axios
    .get(api() + "/subscriptionType/allSubscriptionTypeWithSubs/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Get SubscriptionType by id
const getOneSubscriptionType = async (id) => {
  let statusData;
  await axios
    .get(api() + "/subscriptionType/oneSubscriptionType/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

// Update SubscriptionType
const updateSubscriptionType = async (data) => {
  let statusData;
  await axios
    .put(api() + "/subscriptionType/updateSubscriptionType/" + data.id, data)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response;
    });

  return statusData;
};

// Delete SubscriptionType
const deleteSubscriptionType = async (id) => {
  let statusData;
  await axios
    .delete(api() + "/subscriptionType/deleteSubscriptionType/" + id)
    .then((res) => {
      statusData = res;
    })
    .catch((error) => {
      statusData = error.response.status;
    });

  return statusData;
};

export {
  createSubscriptionType,
  getAllSubscriptionType,
  getAllSubscriptionTypeWithSubs,
  getOneSubscriptionType,
  updateSubscriptionType,
  deleteSubscriptionType,
};
